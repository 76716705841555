@use "../tools" as *;

@mixin i18n-input {
  .i18n-input {
    @include flex-column;
    position: relative;
    .mat-button-toggle-group {
      background-color: #ffffff;
      position: absolute;
      top: -2rem;
      right: 0;
    }
  }
}
