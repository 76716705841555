@use "variables" as *;

@mixin styles {
  .portal-layout {
    --admin-nav-full-width: 250px;
    --admin-nav-icon-width: 55px;
    --admin-nav-icon-button-width: 50px;

    .admin-nav {
      position: fixed;
      overflow: hidden;
      width: var(--admin-nav-full-width);
      transition: width 0.225s ease;
      height: 100svh;
      background-color: var(--color-light);
      &.slim {
        width: var(--admin-nav-icon-width);

        .slim-toggle-button-container {
          > .mat-icon,
          button {
            transform: rotate(180deg);
            margin-left: 0;
          }
        }
      }
    }

    > .page-container {
      position: relative;
      margin-left: var(--admin-nav-full-width);
      transition: margin-left 0.225s ease;
      &.nav-is-slim {
        margin-left: var(--admin-nav-icon-width);
      }
      &.has-no-nav {
        margin-left: 0;
      }

      .page-content-container {
        margin-right: var(--page-container-margin);
        margin-left: var(--page-container-margin);
        .page-content-container {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
