
@mixin styles {
  .sidenav-container {
    position: relative;
    display: flex;
    flex-direction: row;

    > .sidenav-content {
      flex-grow: 1;
    }

    > .sidenav {
      --full-width: 250px;
      --icon-width: 55px;
      --icon-button-width: 50px;
      overflow: hidden;
      width: var(--full-width);
      transition: width 0.225s ease;
      flex-shrink: 0;

      mat-nav-list {
        padding-top: 0;
      }

      .slim-toggle-button-container {
        padding-top: 8px;

        > .mat-icon,
        button {
          transition: transform 0.225s, margin-left 0.225s;
          margin-left: calc(var(--full-width) - var(--icon-button-width));
        }
      }

      &.slim {
        width: var(--icon-width);

        .slim-toggle-button-container {
          > .mat-icon,
          button {
            transform: rotate(180deg);
            margin-left: 0;
          }
        }
      }

      &.closed {
        width: 0;
      }
    }
  }
}
