@use "../tools" as *;
@use "page-editor-component-menu-button-container";
@use "page-editor-component-list";
@use "page-editor-component-selector";
@use "page-editor-component-wrapper";


@mixin styles {

  @include page-editor-component-wrapper.styles;

  .page-editor-layout {
    @include flex-column;

    position: relative;
    .page-editor-container {
      padding-bottom: 2rem;
      > mat-drawer {
        width: 250px;
      }

      .app-dynamic-component {
        > .page-editor-menu-container {
          display: none;
        }
        &:hover {
          position: relative;
          outline: solid 1px #CCCCCC;
          > .page-editor-menu-container {
            position: absolute;
            display: inline-block;
            top: 0;
            right: 0;
          }
        }
      }
    }
    .page-editor-action-container {
      position: fixed;
      top: calc(100vh - 68px);
      right: 2rem;
      @include flex-row;
      @include gap;

      > .project-label {
        line-height: 48px;
        padding-left: 2rem;
        color: #666666;
      }
    }
  }

  @include page-editor-component-menu-button-container.styles;
  @include page-editor-component-list.styles;
  @include page-editor-component-selector.styles
}



