@use "tools" as *;
@use "forms/i18n-input";
@use "forms/project-detail-form";

@mixin styles {
  .form-container {
    @include flex-column;

    position: relative;

    .form-title {
      font-size: 1.6rem;
      padding-bottom: 0.8rem;
    }

    .fields-container {
      gap: 16px;
      padding: 16px;

      .field-container {
        position: relative;

        mat-form-field {
          width: 100%;
        }
      }
    }

    .button-container {
      @include flex-row;
      gap: 1rem;
      padding: 1rem;
    }

    .form-row {
      @include flex-row;
      gap: 1rem;
    }
  }

  @include i18n-input.i18n-input;
  @include project-detail-form.project-detail-form;
}

