@use "../tools" as *;

@mixin styles {
  .auth-process-page {
    @include flex-column;
    @include flex-center;
    padding-top: 5rem;
    .auth-process-content {
      @include flex-column;
      @include flex-center;
      padding-top: 1rem;
      gap: 1rem;
    }
  }
}
