
@mixin styles {
  .dynamic-hero {
    display: block;
    position: relative;
    > img {
      width: 100%;
    }
  }
}
