@use "../tools" as *;

@mixin project-detail-form {
  .project-detail-form {
    @include flex-column;
    .form-container {
      @include flex-column;
      margin: 1rem;
      textarea {
        resize: none;
      }
    }
  }
}
