@use "../tools" as *;

@mixin styles {
  .dynamic-site-header {
    position: relative;
    @include flex-row;
    .dynamic-navigation {
      @include flex-row;
      @include flex-center-horizontal;
      flex-wrap: wrap;
      flex-grow: 1;
      gap: 8px;

      .dynamic-navigation-node {
        display: flex;
        @include flex-center;
        position: relative;
        border-top: 3px solid transparent;
        &:hover {
          border-top-color: var(--color-dark);
        }
      }

      .link-item {
        padding: 1rem;
        > * {
          font-size: 1.2rem;
          font-weight: bold;
          color: var(--color-dark);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .auth-menu {
      display: block;
      height: 3rem;
      width: 3rem;
      > button {
        font-weight: 500;
        > .mat-icon {
          font-size: 1.5rem;
        }
      }
    }
  }

  .dynamic-navigation-node-editor {
    @include flex-column;
    padding-top: 2rem;
    .form-row {
      @include flex-row;
      gap: 1rem;
    }
  }

}

