@use "variables";
@use "fixes";
@use "tools";
@use "layout";
@use "layout/deploy-footer";
@use "features/features";
@use "forms";
@use "admin";
@use "sidenav";
@use "page-editor/page-editor";
@use "map";
@use "components";
@use "asset";
@use "pages/project-tag-page";
@use "pages/auth-process-page";

@mixin styles {
  @include fixes.styles;
  @include tools.styles;
  @include layout.styles;
  @include deploy-footer.styles;
  @include features.styles;
  @include forms.styles;
  @include admin.styles;
  @include sidenav.styles;
  @include page-editor.styles;
  @include map.styles;
  @include components.styles;
  @include asset.styles;
  @include project-tag-page.styles;
  @include auth-process-page.styles;

  .list-filter {
    padding: 0 16px;
  }

  .mat-form-field-full-container {
    position: relative;
    > mat-form-field {
      width: 100%;
    }
  }

  th.actions {
    width: 1%;
  }
}

