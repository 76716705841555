
@mixin rich-map {
  .custom-map-marker {

    .marker-pin {
      width: 40px;
      height: 40px;
      border-radius: 50% 50% 50% 0;
      position: absolute;
      transform: rotate(-45deg);
      left: 50%;
      top: 50%;
      margin: -15px 0 0 -15px;
    }

    .marker-pin::after {
      content: '';
      width: 30px;
      height: 30px;
      margin: 5px 0 0 5px;
      background: rgba(255,255,255,0.85);
      position: absolute;
      border-radius: 50%;
    }

    i {
      position: absolute;
      width: 22px;
      font-size: 22px;
      top: -3px;
      left: 13px;
      margin: 10px auto;
      text-align: center;
      display: inline-block;
    }
  }
}


