@use "../tools" as *;

@mixin styles {
  .page-editor-component-selector {
    @include flex-column;
    .component-selection-container {
      @include flex-row;
      @include flex-center;
      flex-wrap: wrap;
      gap: 1rem;
      height: 0;
      transition: height ease-in-out 0.225s;
      overflow: hidden;
      padding: 4px;
      &.open {
        height: 180px;
      }
      .component-select-button {
        cursor: pointer;
        text-align: center;
        border-radius: 1rem;
        outline: solid 1px #CCCCCC;

        width: 150px;
        height: 150px;
        line-height: 150px;

        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;

        -webkit-user-drag: none;
        &:hover {
          background-color: rgba(0,0,0,0.1);
        }
      }
    }
  }
}

