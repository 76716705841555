@use "tools" as *;

@mixin styles {
  .admin-asset-list {
    @include flex-row;
    @include flex-wrap;
    @include gap();

    .admin-asset-card {
      position: relative;
      @include flex-column;

      outline: solid 1px #cccccc;
      background-color: #ffffff;
      width: 250px;
      .asset-card-thumb-container {
        background-color: #cccccc;
        height: 200px;
        overflow: hidden;
        img {
          width: 100%;
          object-fit: scale-down;
        }
        .asset-as-icon {
          height: 200px;
          line-height: 200px;
          text-align: center;
          color: #999999;
          .mat-icon {
            transform: scale(5);
          }
        }
      }
      .image-card-footer {
        border-top: solid 1px #cccccc;
        .file-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0.5rem 1rem;
        }
      }
      .button-container {
        @include flex-row;
        padding-right: 0.5rem;
      }
    }
  }

  .admin-asset-selected {
    @include flex-row;
    gap: 1rem;
    .symbol {
      img {
        max-height: 32px;
      }
    }
  }
}
