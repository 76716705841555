@use "../tools" as *;

@mixin styles {
  .pinboard-entry-display {
    @include flex-column;
    @include gap;
    .pinboard-entry-title {
      font-size: 2rem;
    }
    .pinboard-entry-content {
      p {
        text-align: justify;
        text-justify: inter-word;
      }
    }
  }
}

