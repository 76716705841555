@use "tools" as *;

// content of the map has a z-index of 400 // from .leaflet-pane
// controls has a z-index of 800 // from .leaflet-control


@mixin styles {

  // map without the .rich-map container
  .map-container {
    display: block;
    position: relative;
    .map {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      height: 600px;
      background-color: #d4e3fc;
    }
  }

  .rich-map {
    display: flex;
    flex-direction: column;
    position: relative;
    .rich-map-map-container {
      position: relative;
      .rich-map-map-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 1000;
        transition: bottom .25s ease-in-out;
        overflow-y: auto;
        &.hide {
          overflow: hidden;
          bottom: 100%;
        }

        @include flex-column;
      }
    }
  }

  .rich-map-comment-form {
    h3 {
      padding-left: 1rem;
    }
    .form-container {
      padding-bottom: 1rem;
    }
    .field-container.comment {
      textarea {
        resize: none;
        height: 150px;
      }
    }
  }
}

