@use "tools" as *;


@mixin styles {
  .admin-page {
    @include flex-column;
    background-color: #EEEEEE;
    --distance: 64px;

    .admin-page-header {
      margin: 16px var(--distance) 0 var(--distance);
      @include flex-column;

      .info-container {
        opacity: 0.5;
      }

      .title {
        flex-grow: 1;
        font-size: 2rem;
      }
    }

    .toolbar {
      @include flex-row;
      @include gap;
      margin: 16px var(--distance) 0 var(--distance);

      .button-container {
        padding-top: 16px;
      }
    }

    .content-container,
    .form-container,
    .list-container {
      margin: 16px var(--distance) 0 var(--distance);
      padding-bottom: 128px;
    }
  }

}

