@use "../tools" as *;
@use "pinboard-entry-card";

@mixin styles {
  .pinboard {
    @include flex-column;
    flex: 1;
    padding-bottom: 3rem;
    .pinboard-title {
      font-size: 2rem;
      text-align: center;
      padding-bottom: 2rem;
    }

    .entry-container {
      @include flex-row;
      gap: 1rem;
      flex-wrap: wrap;
      @include flex-center;
      @include pinboard-entry-card.styles;
    }
  }

  .pinboard-entry-form {
    @include flex-column;
    .form-row {
      > * {
        flex-grow: 1;
      }
    }
  }

}


