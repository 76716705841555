@use "../tools" as *;

@mixin styles {
  .article-component {
    @include flex-column;
    .headline {
      font-size: 1.5rem;
      padding-bottom: 1rem;
    }
    .article-content {
      @include flex-row;
      gap: 2rem;
      position: relative;
      .image-container {
        width: 35%;
        position: relative;
        flex-shrink: 0;
        img {
          width: 100%;
        }
      }
      .text-container {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }

  .article-editor {
    @include flex-column;
    gap: 2rem;
    .form-row {
      white-space: wrap;
      @include flex-column;
    }
  }
}
