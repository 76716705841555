@use "../tools" as *;


@mixin styles {
  .deploy-footer {
    --color-bg: #8cc7ff;
    --color-link: #314e9c;
    @include flex-row;
    @include flex-wrap;
    background-color: var(--color-bg);
    gap: 1rem;

    margin-top: 2rem;
    padding-bottom: 5rem;

    > div {
      flex-grow: 1;
      padding: 1rem;
    }
    div.logos {
      @include flex-row;
      @include flex-center;
      gap: 1rem;
      img {
        width: 120px;
      }
    }
    div.disclaimer,
    div.links {
      p {
        color: black;
        a {
          color: var(--color-link);
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}


