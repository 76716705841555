@use "../variables" as *;
@use "../tools" as *;

@mixin styles {

  .hero-teaser {
    @include flex-column;
    @include flex-center;
    position: relative;

    margin-left: calc(0px - var(--page-container-margin));
    margin-right: calc(0px - var(--page-container-margin));

    > img {
      width: 100%;
    }

    .placeholder {
      @include flex-column;
      @include prevent-select;
      background-color: lightblue;
      height: 250px;
      width: 90%;
      font-size: 2rem;
      line-height: 250px;
      text-align: center;
      color: dodgerblue;
    }
  }

  // reset after first level
  .page-editor-component-wrapper .page-editor-component-wrapper .hero-teaser {
    margin-left: 0;
    margin-right: 0;
  }

  .hero-teaser-editor {
    @include flex-column;
  }
}
