@use "components/login-form";
@use "components/dynamic-site-header";
@use "components/dynamic-hero";
@use "components/hero-teaser";
@use "components/pinboard";
@use "components/pinboard-entry-display";
@use "components/article";
@use "components/component-container";

@mixin styles {

  .component-title {
    font-size: 2rem;
    text-align: center;
    padding-bottom: 2rem;
  }

  @include login-form.styles;
  @include dynamic-site-header.styles;
  @include dynamic-hero.styles;
  @include hero-teaser.styles;
  @include pinboard.styles;
  @include pinboard-entry-display.styles;
  @include article.styles;
  @include component-container.styles;
}
