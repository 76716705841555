
@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-wrap {
  flex-wrap: wrap;
}



@mixin gap {
  gap: 1rem;
}

@mixin flex {
  flex-grow: 1;
  flex-shrink: 0;
}


@mixin flex-center-horizontal {
  justify-content: center;
}


@mixin flex-center-vertical {
  align-items: center;
}

@mixin flex-center {
  justify-content: center;
  align-items: center;
}

@mixin prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@mixin text-line-cut {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

///////////////////////////////////////////////////////////////////////

@mixin styles {
  .flex-row {
    @include flex-row;
  }

  .flex-column {
    @include flex-column;
  }

  .flex-wrap {
    @include flex-wrap;
  }

  .gap {
    @include gap;
  }

  .flex {
    @include flex;
  }

  .flex-center-horizontal {
    @include flex-center-horizontal;
  }

  .flex-center-vertical {
    @include flex-center-vertical;
  }

  .flex-center {
    @include flex-center;
  }

  .inline-block {
    display: inline-block;
  }

  .prevent-select {
    @include prevent-select;
  }

  .text-line-cut {
    @include text-line-cut;
  }

  .dev-hide {
    display: none !important;
    visibility: hidden !important;
  }
}

