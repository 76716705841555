@use "../tools" as *;

@mixin styles {
  .project-tag-page {
    .content-container {
      @include flex-row;
      gap: 2rem;
      .tag-form {
        padding: 1rem;
        background-color: #FFFFFF;
      }

      .tag-list {
        padding: 1rem;
        flex: 1;
        background-color: #FFFFFF;
      }
    }
  }
}
