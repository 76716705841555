@use "../tools" as *;

@mixin styles {
  .component-container {
    @include flex-column;
    //background-color: orange;
    .component-container-layout {
      //background-color: #4b81cb;

      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
    }

    .component-container-column {
      @include flex-column;
      position: relative;
      //background-color: lime;
      min-height: 50px;

      .component-container-column-menu-container {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

  }

  .component-container.page-editor-component-container {
    padding-top: 2.5rem;
  }
}
