
@mixin styles() {
  .page-editor-component-wrapper {
    position: relative;
    min-height: 100px;
    margin-bottom: 1rem;
    .page-editor-component-menu {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
