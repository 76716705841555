@use "../tools" as *;

@mixin styles {

  .pinboard-entry-card {
    @include flex-column;
    width: 250px;

    .pinboard-entry-card-title {
      font-size: 1.5rem;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .pinboard-entry-card-author-name {
      padding-top: 0.5rem;
      font-size: 0.7rem;
    }

    .pinboard-entry-card-button {
      button {
        height: 1.7rem;
      }
    }

    .pinboard-entry-card-content {
      margin-top: 0.5rem;

      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        margin: 0;
      }

      &.size-3 {
        -webkit-line-clamp: 3;
      }
      &.size-5 {
        p {
          -webkit-line-clamp: 5;
        }
      }
      &.size-10 {
        p {
          -webkit-line-clamp: 10;
        }
      }
    }

    .badge {
      padding-top: 0.5rem;
    }
  }

}
