
@mixin styles {
  .mat-mdc-menu-panel .mat-mdc-menu-content {
    padding-top: 0;
    padding-bottom: 0;
    > .mat-divider {
      margin-top: 0;
      margin-bottom: 0;
    }

    .mat-mdc-menu-item:not([disabled]).no-hover:hover {
      background-color: inherit;
      cursor: default;
    }
  }
}

